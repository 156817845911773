.cooperate-index-warp{
  width: inherit;
  background-color: #FFFFFF;
  padding-top: 80px;
}
.cooperate-index-warp .cooperate-warp-header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.cooperate-index-warp .cooperate-banner-warp{
  width: 100%;
  position: relative;
}
.cooperate-index-warp .cooperate-banner-warp img{
  width: 100%;
}
.cooperate-index-warp .cooperate-banner-warp .banner-warp-btn{
  position: absolute;
  left: 50%;
  bottom: 116px;
  margin-left: -90px;
  width: 180px;
  height: 52px;
  font-size: 18px;
  line-height: 52px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  border-radius: 50px;
  user-select: none;
  transition: all .3s ease-in;
}
.cooperate-index-warp .cooperate-banner-warp .banner-warp-btn:hover{
  color: #FFFFFF;
  cursor: pointer;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  box-shadow: 0px 5px 5px #ccc;
  opacity: 0.8;
}
.cooperate-index-warp .cooperate-banner-warp .banner-warp-btn:active{
  color: #FFFFFF;
  cursor: pointer;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  opacity: 1;
}
.cooperate-index-warp .cooperate-warp-content{
  width: 100%;
  background-color: #FFFFFF;
  margin-bottom: 60px;
}
.cooperate-warp-content-title{
  padding: 60px 40px;
  padding-bottom: 0;
  line-height: 36px;
  user-select: none;
}
.cooperate-warp-content-title .title-active{
  width: 100px;
  height: 36px;
  line-height: 36px;
  color: #0B87FC;
  background: #0b88fc0f;
  border-radius: 50px 50px 50px 50px;
}
.cooperate-index-warp .cooperate-warp-content .cooperate-warp-content-col img{
  width: 100%;
}
.cooperate-index-warp .cooperate-warp-content .cooperate-example-content{
  /* margin-top: 40px;
  display: inline-block;
  width: 270px;
  height: 375px;
  padding: 16px;
  border-radius: 12px;
  color: #000;
  text-align: left;
  background-color: #FFFFFF;
  box-shadow: 5px 5px 5px 5px rgba(225,234,255,.3); */
}
.cooperate-index-warp .cooperate-warp-content .cooperate-example-content img{
 width: 100%;
}
.cooperate-example-title{
  font-size: 13px;
  font-weight: 600;
  margin-top: 30px;
  color: #7b7e84;
}
.cooperate-example-info{
  font-size: 1rem;
  margin-top: 30px;
  font-weight: 600;
}