.header-warp-t{
  background-color: transparent;
  color: #646A73;
  user-select: none;
  transition: background-color .4s ease;
}
.header-warp-w{
  background-color: #FFFFFF;
  color: #646A73;
  user-select: none;
  transition: background-color .4s ease;
}
.header-warp-t:hover{
  background-color: #FFFFFF;
}
.header-warp-logo img{
  height: 32px;
  vertical-align: middle;
}
.ant-menu-title-content:hover{
  color: #0B87FC;
}
.ask-btn-content{
  display: flex;
  height: 100%;
  position: relative;
}
.btn{
  position: absolute;
  right: 0;
  top: 50%;
  display: inline-block;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  color: #FFFFFF;
  text-align: center;
  background: linear-gradient(90deg, #296CF0 0%, #39B8FD 100%);
  border-radius: 20px;
  margin: auto;
  text-decoration: none;
  margin-top: -16px;
  user-select: none;
  transition: all .3s ease-in;
}
.btn:hover{
  cursor: pointer;
  color: #FFFFFF;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  box-shadow: 0px 5px 5px #ccc;
  opacity: 0.8;
}
.btn:active{
  cursor: pointer;
  color: #FFFFFF;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  opacity: 1;
}
.menu-btn{
  display: inline-block;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  color: #FFFFFF !important;
  text-align: center;
  background: linear-gradient(90deg, #296CF0 0%, #39B8FD 100%);
  border-radius: 20px;
  margin: auto;
  text-decoration: none;
  margin-top: -16px;
  user-select: none;
  transition: all .3s ease-in;
}
/* .ant-menu-light .ant-menu-item a, .ant-menu-light>.ant-menu .ant-menu-item a, .ant-menu-light .ant-menu-item a:hover, .ant-menu-light>.ant-menu .ant-menu-item a:hover {
  color: #FFFFFF;
} */
.menu-btn:hover{
  cursor: pointer;
  color: #FFFFFF;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  box-shadow: 0px 5px 5px #ccc;
  opacity: 0.8;
}
.menu-btn:active{
  cursor: pointer;
  color: #FFFFFF;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  opacity: 1;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  justify-content: space-between;
}
.ant-menu-overflow {
  display: flex;
  justify-content: flex-end;
}