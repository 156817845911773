.products-warp {
  user-select: none;
  padding-top: 80px;
}
.products-warp .warp-header{
  height: 100%;
  background-image: url('../../../../image/products/logo.png');
  background-size: 100% 100%;
}
.products-warp .header-title {
  padding-top: 40px;
  font-size: 44px;
  line-height: 44px;
  font-weight: 700;
}
.products-warp .header-details {
  color: #646a73;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
}
.products-warp .content-warp{
  margin-top: 30px;
}
.products-warp .content-warp-item {
  max-width: 250px;
  height: 300px;
  margin: auto;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 20px;
  margin-bottom: 50px;
  transition: all .3s ease;
}
.products-warp .content-warp-item:hover {
  transform: scale(1.1);
  box-shadow: 4px 4px 30px 0px rgba(3,11,87,0.05);
}
.products-warp .content-warp-item:hover .warp-item-btn {
  display: block;
}
.products-warp .warp-item-btn {
  display: none;
}
.products-warp .item-btn-content {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(242, 248, 253,0.9) 0%, rgb(238, 244, 253,0.9) 100%);
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  justify-content: center;
}
.products-warp .btn-content-item {
  text-align: center;
  display: inline-block;
  color: #ffffff;
  width: 100px;
  height: 36px;
  line-height: 36px;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  border-radius: 50px 50px 50px 50px;
  text-decoration: none;
  margin: 10px auto;
}
.products-warp .btn-content-item:hover {
  color: #ffffff;
}
.products-warp .btn-content-item:nth-child(2) {
  background: transparent;
  color: #3370FF;
  border: 1px solid #3370FF;
}
.products-warp .btn-content-item:nth-child(2):hover {
  color: #3470fc;
}
