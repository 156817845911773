.adviser-warp{
  user-select: none;
  padding-top: 80px;
}
.adviser-warp  .warp-header{
  height: 100%;
  background-image: url('../../../../image/adviser-logo.png');
  background-size: 100% 100%;
}
.adviser-warp .header-title{
  padding-top: 40px;
  font-size: 44px;
  line-height: 44px;
  font-weight: 700;
}
.adviser-warp .header-details{
  color: #646A73;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
}
.adviser-warp .content-warp{
  width: 100%;
  margin-top: 40px;
}
.adviser-warp .content-warp img{
  width: 100%;
}
.adviser-warp .content-warp{
  position: relative;
}
.adviser-warp .content-warp .content-warp-btn{
  position: absolute;
  right: 31%;
  bottom: 90px;
  width: 180px;
  height: 52px;
  font-size: 16px;
  line-height: 52px;
  text-align: center;
  text-decoration: none;
  color: #FFFFFF;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  border-radius: 50px;
  transition: all .3s ease-in;
}
.adviser-warp .content-warp .content-warp-btn:hover{
  color: #FFFFFF;
  cursor: pointer;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);;
  box-shadow: 0px 5px 5px #ccc;
  opacity: 0.8;
}
.adviser-warp .content-warp .content-warp-btn:active{
  color: #FFFFFF;
  cursor: pointer;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  opacity: 1;
}