.project-warp {
  user-select: none;
}
.project-warp .header-btn {
  display: inline-block;
  margin: 0 20px;
  cursor: default;
  user-select: none;
  transition: all .3s ease-in;
}
.project-warp .header-btn:hover {
  cursor: pointer;
  color: #397afc;
}
.project-warp .header-btn-active {
  user-select: none;
  cursor: default;
  color: #ffffff;
  display: inline-block;
  /* width: 110px; */
  padding-left: 25px;
  padding-right: 25px;
  height: 36px;
  line-height: 36px;
  margin: 0 20px;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  border-radius: 50px;
}
.project-warp .content-warp {
  width: 100%;
  padding-bottom: 60px;
  position: relative;
}
.project-warp .content-warp img {
  width: 100%;
}
.project-warp .content-warp-btn {
  position: absolute;
  left: 120px;
  bottom: 130px;
  width: 180px;
  height: 52px;
  line-height: 52px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  border-radius: 50px;
  opacity: 1;
  user-select: none;
  transition: all .3s ease-in;
}
.project-warp .content-warp-btn:hover {
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  box-shadow: 0px 5px 5px #ccc;
  opacity: 0.8;
}
.project-warp .content-warp-btn:active {
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  opacity: 1;
}
