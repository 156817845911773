.products-warp {
  width: inherit;
  background-color: #ffffff;
  padding-top: 80px;
}
.products-warp .products-warp-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.products-warp .products-banner-warp {
  width: 100%;
  position: relative;
}
.products-warp .products-banner-warp img {
  width: 100%;
}
.products-warp .products-banner-warp .banner-warp-btn {
  position: absolute;
  left: 50%;
  bottom: 116px;
  margin-left: -90px;
  width: 180px;
  height: 52px;
  font-size: 18px;
  line-height: 52px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  border-radius: 50px;
  user-select: none;
  transition: all .3s ease-in;
}
.products-warp .products-banner-warp .banner-warp-btn:hover {
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  box-shadow: 0px 5px 5px #ccc;
  opacity: 0.8;
}
.products-warp .products-banner-warp .banner-warp-btn:active {
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  opacity: 1;
}
.products-warp .products-warp-content {
  margin-top: 70px;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 60px;
}
.products-warp .products-warp-content .warp-content-left {
  padding-right: 20px;
}
.products-warp .products-warp-content .content-title {
  color: #1f2329;
}
.products-warp .products-warp-content .content-ul {
  /* padding: 0 10px; */
}
.products-warp .products-warp-content .content-txt {
  position: relative;
  color: #646a73;
  text-align: left;
  line-height: 60px;
  padding-right: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.products-warp .products-warp-content .content-txt:hover {
  cursor: pointer;
  color: #1f2329;
}
.products-warp .products-warp-content .content-txt-active {
  color: #1f2329;
  border-bottom: 1px solid #1f2329;
  font-weight: 600;
}
.content-txt .icon {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -8px;
}
.products-warp .products-warp-content .content-iframe {
  width: 100%;
  height: 1000px;
}
