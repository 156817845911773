.scheme-warp {
  width: 100%;
  user-select: none;
  background: linear-gradient(180deg, #f7fbff 0%, #eef4fd 100%);
  padding-top: 60px;
}
.scheme-warp .warp-header{
  height: 100%;
  background-image: url('../../../../image/scheme/scheme-logo.png');
  background-size: 100% 100%;
}
.scheme-warp .header-title {
  padding-top: 40px;
  font-size: 44px;
  line-height: 44px;
  font-weight: 700;
}
.scheme-warp .header-details {
  display: inline-block;
  color: #0b87fc;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
}
.scheme-warp .header-details:hover {
  color: #007ce2;
}
.scheme-warp .header-details:active {
  color: #007ce2;
}
.scheme-warp .scheme-content {
  position: relative;
}
.scheme-warp .content-warp {
  width: 100%;
  padding-bottom: 60px;
  color: transparent;
}
.scheme-warp .content-warp-item {
  display: flex;
}
.scheme-warp .warp-item-txt {
  position: relative;
}
.scheme-warp .warp-item-txt img {
  width: 100%;
  margin-top: 20%;
}
.scheme-warp .item-txt-btn {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 180px;
  height: 52px;
  line-height: 52px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  border-radius: 50px;
  user-select: none;
  transition: all .3s ease-in;
}
.scheme-warp .warp-item-txt .item-txt-btn:hover {
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  box-shadow: 0px 5px 5px #ccc;
  opacity: 0.8;
}
.scheme-warp .warp-item-txt .item-txt-btn:active {
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  opacity: 1;
}
.scheme-warp .warp-item-img {
  position: relative;
}
.scheme-warp .warp-item-img img {
  width: 100%;
}
.scheme-warp .arrow-btn {
  position: absolute;
  right: 350px;
  bottom: 50px;
}
.scheme-warp .icon-left,
.scheme-warp .icon-right {
  background-color: #ffffffff;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin: 0 25px;
  cursor: pointer;
}
.scheme-warp .icon-left:hover path,
.icon-right:hover path {
  fill: #0b87fc;
  background-color: #ffffffff;
  border-radius: 50%;
  margin: 0 5px;
}
