.footer-warp{
  width: 100%;
}
.footer-warp-item{
  width: 100%;
  height: auto;
  background-color: rgba(247,251,255);
  text-align: center;
  color: rgba(104,109,118);
}
.footer-warp-item a{
  color: #296cf0;
}
.footer-warp-item:last-child{
  padding-bottom: 14px;
  font-size: 14px;
}
.footer-warp-item-img{
  width: 100%;
  height: auto;
}