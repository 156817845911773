* {   
  box-sizing: border-box;   
}
div,img,span,a,ul,li,ol,dl,dt,dd,h1,h2,h3,h4,h5,h6,form,input,button,p,table,tr,td,b,strong,i,em,textarea,select,body{
  padding:0;
  margin:0;
} 
table{   
  border-collapse:collapse;   
} 
body {   
  font-family: "Microsoft YaHei"; 
  margin: 0;
  padding: 0;  
}

ul, li {   
  list-style: none;   
}

a {   
  text-decoration: none;   
  color: #232323;   
}

input, textarea {   
  outline: none;   
  box-shadow: none;   
}

textarea {   
  resize: none;   
  overflow: auto;   
}

.fl {   
  float: left   
}

.fr {   
  float: right   
}

.tl {   
  text-align: left;   
}

.tc {   
  text-align: center   
}

.tr {   
  text-align: right;   
}

.ellipse {   
  overflow: hidden;   
  text-overflow: ellipsis;   
  white-space: nowrap;   
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

.not-allowed {
  cursor: not-allowed !important;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  not supported by any browser */
}

.btn{
  display: inline-block;
  padding: 3px 5px;
  text-align: center;
  background: linear-gradient(90deg, #296CF0 0%, #39B8FD 100%);
  border-radius: 20px;
  margin: auto;
  text-decoration: none;
  transition: all .3s ease-in;
}

.btn:hover{
  cursor: pointer;
  background: linear-gradient(90deg, #0B87FC 0%, #296CF0 100%);
  box-shadow: 0px 5px 5px #ccc;
}