.cooperate-detail-index-warp{
  width: inherit;
  background-color: #FFFFFF;
  padding-top: 80px;
}
.cooperate-detail-index-warp .cooperate-detail-warp-header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.cooperate-detail-index-warp .cooperate-detail-banner-warp{
  width: 100%;
  position: relative;
}
.cooperate-detail-index-warp .cooperate-detail-banner-warp img{
  width: 100%;
}
.cooperate-detail-index-warp .cooperate-detail-banner-warp .banner-warp-btn{
  position: absolute;
  left: 50%;
  bottom: 116px;
  margin-left: -90px;
  width: 180px;
  height: 52px;
  font-size: 18px;
  line-height: 52px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  border-radius: 50px;
  user-select: none;
  transition: all .3s ease-in;
}
.cooperate-detail-index-warp .cooperate-detail-banner-warp .banner-warp-btn:hover{
  color: #FFFFFF;
  cursor: pointer;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  box-shadow: 0px 5px 5px #ccc;
  opacity: 0.8;
}
.cooperate-detail-index-warp .cooperate-detail-banner-warp .banner-warp-btn:active{
  color: #FFFFFF;
  cursor: pointer;
  background: linear-gradient(90deg, #397AFC 0%, #39B8FD 100%);
  opacity: 1;
}
.cooperate-detail-index-warp .cooperate-detail-warp-content{
  width: 100%;
  background-color: #FFFFFF;
  margin-bottom: 60px;
}
.cooperate-detail-index-warp .cooperate-detail-warp-content .content-top{
  display: flex;
  background-color: rgba(225,234,255,.3);
}
.cooperate-detail-index-warp .cooperate-detail-warp-content div img{
  width: 100%;
}