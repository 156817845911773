.serve-warp{
  user-select: none;
  padding-top: 80px;
}
.serve-warp  .warp-header{
  height: 100%;
  background-image: url('../../../../image/serve-logo.png');
  background-size: 100% 100%;
}
.serve-warp .header-title{
  padding-top: 40px;
  font-size: 44px;
  line-height: 44px;
  font-weight: 700;
}
.serve-warp .header-details{
  color: #646A73;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
}
.serve-warp .content-warp{
  width: 100%;
  margin-top: 40px;
  padding-bottom: 60px;
}
.serve-warp .content-warp img{
  width: 100%;
}