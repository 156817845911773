.banner-warp {
  width: 100%;
  user-select: none;
}
.banner-warp .banner-warp-item {
  width: 100%;
  position: relative;
}
.banner-warp .banner-warp-item .banner-img {
  width: 100%;
  height: 100%;
}
.banner-warp .banner-warp-item .banner-btn {
  position: absolute;
  left: 130px;
  bottom: 130px;
  width: 180px;
  height: 52px;
  line-height: 52px;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  border-radius: 50px;
  user-select: none;
  transition: all .3s ease-in;
}
.banner-warp .banner-warp-item .banner-btn:hover {
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  box-shadow: 0px 5px 5px #ccc;
  opacity: 0.8;
}
.banner-warp .banner-warp-item .banner-btn:active {
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  opacity: 1;
}
.ant-carousel
  .slick-dots
  li.slick-active {
  width: 44px;
  height: 8px;
  background: linear-gradient(90deg, #397afc 0%, #39b8fd 100%);
  border-radius: 66px 66px 66px 66px;
}
.ant-carousel
  .slick-dots
  li.slick-active
  button {
  background: transparent;
  opacity: 1;
}
.ant-carousel .slick-dots li {
  width: 8px;
  height: 8px;
  background: #e8e8e8;
  border-radius: 50%;
  opacity: 1;
}
.ant-carousel .slick-dots-bottom{
  bottom: 30px;
}
