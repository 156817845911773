.cooperate-warp{
  width: 100%;
  user-select: none;
  background: linear-gradient(180deg, #F7FBFF 0%, #EEF4FD 100%);
  padding-top: 60px;
  padding-bottom: 120px;
}
.cooperate-warp .warp-header{
  height: 100%;
  background-image: url('../../../../image/cooperate/cooperate-logo.png');
  background-size: 100% 100%;
}
.cooperate-warp .header-title{
  padding-top: 40px;
  font-size: 44px;
  line-height: 44px;
  font-weight: 700;
}
.cooperate-warp .header-details{
  display: inline-block;
  color: #0b87fc;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
}
.cooperate-warp .header-details:hover{
  color: #007CE2;
}
.cooperate-warp .header-details:active{
  color: #007CE2;
}
.cooperate-warp .cooperate-content{
  position: relative;
}
.cooperate-warp .content-warp{
  width: 100%;
  color: transparent;
}
.cooperate-warp .warp-item-img img{
  width: 100%;
}
.cooperate-warp .cooperate-footer {
  margin-top: 60px;
}
.cooperate-warp .cooperate-footer .warp-item-img-circle div{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  margin: auto;
  background-size: contain;
}
.cooperate-warp .icon-left, .cooperate-warp .icon-right {
  background-color: #FFFFFFFF;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}
.cooperate-warp .icon-left:hover path,.icon-right:hover path{
  fill: #0B87FC;
  background-color: #FFFFFFFF;
  border-radius: 50%;
  margin: 0 5px;
}
